<template>
  <el-container>
    <el-header
      :style="{ backgroundColor: currentTheme.barBackgroundColor }"
      class="shadow-border-bottom"
    >
      <div
        :style="{
          color: currentTheme.logoTextColor,
          backgroundColor: currentTheme.logoBackgroundColor,
          width: collapse ? '79px' : '285px',
        }"
        class="logo-container"
      >
        <el-button
          class="boreder-right"
          type="text"
          @click="collapse = !collapse"
          :style="{ color: currentTheme.logoTextColor }"
        >
          <em
            class="iconfont"
            style="font-size: 30px"
            :class="collapse ? 'icon-indent' : 'icon-outdent'"
          >
          </em>
        </el-button>
        <transition name="el-fade-in">
          <img
            v-show="!collapse"
            src="@/assets/images/logo.png"
            alt=""
            @click="goHomePage"
          />
        </transition>
        <!-- <transition name="el-fade-in">
          <span v-show="!collapse">BUNKER CHAIN</span>
        </transition> -->
      </div>
      <div class="top-container">
        <div class="left">
          <!--收起/展开导航菜单-->
          <!-- <div
            class="top-block"
            @click="collapse=!collapse">
            <el-button
              type="text"
              :style="{color: currentTheme.barTextColor}">
              <em class="iconfont" :class="collapse?'icon-indent':'icon-outdent'"></em>
            </el-button>
          </div> -->
          <!--全屏切换-->
          <!-- <div
            class="top-block"
            @click="toggleFullScreen">
            <el-button
              type="text"
              :style="{color: currentTheme.barTextColor}">
              <em class="iconfont icon-expend"></em>
            </el-button>
          </div> -->
          <!--切换企业或团队-->
          <div class="top-block" v-if="companyOptions.length">
            <el-cascader
              class="switch-company"
              :style="{ color: currentTheme.barTextColor }"
              v-model="currentCompanyTeam"
              :placeholder="$t('selectCompanyTeam')"
              :options="companyOptions"
              @change="handleCompanyTeamChange"
            >
              <template slot-scope="{ data }">
                <em
                  style="margin-right: 5px"
                  :class="data.type === 'team' ? 'el-icon-files' : 'el-icon-suitcase'"
                ></em>
                <span>{{ data.label }}</span>
              </template>
            </el-cascader>
          </div>
        </div>
        <div class="right">
          <!--选择主题-->
          <!-- <theme-picker
            @checkedTheme="onCheckedTheme">
            <div class="top-block">
              <el-button
                type="text"
                style="font-size: 18px"
                :style="{color: currentTheme.barTextColor}">
                <em class="iconfont icon-skin"></em>
              </el-button>
            </div>
          </theme-picker> -->
          <!--语言切换-->
          <!-- <div
            class="top-block">
            <language-selector :style="{color:currentTheme.barTextColor}">
            </language-selector>
          </div> -->
          <!--消息盒子-->
          <!-- <div
            class="top-block">
            <message-box
              :items="messageDatas"
              @clear="websocketsend"
              @clearAll="websocketsendall">
            </message-box>
          </div> -->
          <!--用户中心-->
          <div class="top-block">
            <personal-center-popover
              :username="user.username || 'admin'"
            ></personal-center-popover>
          </div>
        </div>
      </div>
    </el-header>
    <el-container class="container-body">
      <el-aside
        :class="{ 'is-collapse': collapse }"
        :style="{ backgroundColor: currentTheme.menuBackgroundColor }"
        :width="collapse ? '79px' : '250px'"
      >
        <div class="menu-container">
          <el-menu
            ref="elMenu"
            :default-active="defaultActive"
            :background-color="currentTheme.menuBackgroundColor"
            :text-color="currentTheme.menuTextColor"
            :active-text-color="'#fff'"
            :unique-opened="true"
            :collapse="collapse"
          >
            <template v-for="menu in leftMenulist">
              <el-submenu
                v-if="menu.children.length && menu.enable"
                :key="menu.name"
                :index="menu.id"
              >
                <template slot="title">
                  <em :class="menu.icon"></em>
                  <span>{{ $t(menu.name) }}</span>
                </template>
                <template v-for="item in menu.children">
                  <el-menu-item
                    v-if="item.enable"
                    :key="item.id"
                    :index="`/admin/${item.router}`"
                    @click="toCurrentPage(item)"
                  >
                    <div class="item">
                      <em :class="item.icon"></em>
                      <span>{{ $t(item.name) }}</span>
                    </div>
                  </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item
                v-if="!menu.children.length && menu.enable"
                :key="menu.id"
                :index="`/admin/${menu.router}`"
                @click="toCurrentPage(menu)"
              >
                <em :class="menu.icon"></em>
                <span slot="title">
                  {{ $t(menu.name) }}
                </span>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
      </el-aside>
      <el-main>
        <!-- <div class="navigation-tag">
          <navigation-tag></navigation-tag>
        </div> -->
        <div class="main">
          <div class="sub-main">
            <router-view v-if="isRouterAlive" />
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { toggleFullScreen } from "@/utils";
// import ThemePicker from './components/ThemePicker'
import MessageBox from "./components/MessageBox";
// import NavigationTag from './components/NavigationTag'
// import LanguageSelector from './components/LanguageSelector'
import PersonalCenterPopover from "./components/PersonalCenterPopover";
import { mapState } from "vuex";
import baseMixin from "@/mixins/base-mixin";
let timeout = null;

export default {
  name: "Admin",
  mixins: [baseMixin],
  components: {
    // ThemePicker,
    MessageBox,
    // NavigationTag,
    // LanguageSelector,
    PersonalCenterPopover,
  },
  provide() {
    // 提供reload方法
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      websock: null,
      messageDatas: [],
      collapse: false,
      isRouterAlive: true,
      currentCompanyTeam: [],
      companyOptions: [],
      currentMsgId: "",
      destroy: 0, //  判断断连，还是最后关闭
    };
  },
  created() {
    // this.initWebSocket()
    this.setCompanyOptions();
    this.$eventBus.$on("toggleFullscreen", (isFullScreen) => {
      this.collapse = !isFullScreen;
    });
  },
  destroyed() {
    this.destroy = 1;
    this.websock.close(); // 离开路由之后断开websocket连接
  },
  computed: {
    ...mapState({
      menus: "menus",
      currentTheme: "currentTheme",
      currentTag: "currentTag",
      user: "user",
      userExt: "userExt",
      permissions: "permissions",
    }),
    defaultActive() {
      return this.$route.path;
    },
    leftMenulist() {
      if (this.permissions.hasSysAdminPermission) {
        return this.menus;
      } else if (this.permissions.hasShipPermission) {
        return [];
      } else {
        let list = this.menus.filter((menu) => menu.name !== "AUDIT_LOGGING");
        if (!this.permissions.hasCompanyAdminPermission)
          list = list.filter((menu) => menu.name !== "SYSTEM_MANAGEMENT");
        if (list?.length > 0) {
          list.forEach((item, pIndex) => {
            if (item.name === "SYSTEM_MANAGEMENT") {
              item.children = item.children.filter(
                (childItem) =>
                  childItem.name !== "MENU_MANAGEMENT" &&
                  childItem.name !== "FUNCTION_MANAGEMENT" &&
                  childItem.name !== "FILE_MANAGEMENT" &&
                  childItem.name !== "Calendar Management" &&
                  childItem.name !== "SYSTEM_SETTING_MANAGEMENT"
              );
            }
          });
        }
        return list || [];
      }
    },
    hasSysAdminPermission() {
      return this.$store.state.permissions.hasSysAdminPermission;
    },
  },
  mounted() {
    // this.setMouseStyle(this.companyOptions)
    // 设置当前选中的团队
    // 页面刷新后仍旧保持当前公司状态
    if (!this.hasSysAdminPermission) {
      if (this.$store.state.currentCompany) {
        const parentValue1 = this.$store.state.currentCompany.id;
        const childValue1 =
          this.$store.state?.currentTeamId ??
          this.$store.state.currentCompany.teams[0].id;
        try {
          this.currentCompanyTeam = [parentValue1, childValue1];
        } catch (e) {}
      } else {
        try {
          this.currentCompanyTeam = [
            this.companyOptions[0].value,
            this.companyOptions[0].children[0].value,
          ];
        } catch (e) {}
      }
    }
  },

  methods: {
    goHomePage() {
      if (this.$route.fullPath !== "/admin/welcome") {
        this.$router.replace("/admin/welcome");
      }
    },
    // initWebSocket () { // 初始化weosocket
    //   const wsuri = this.$apis.websocketBaseUrl + this.userExt.id
    //   this.websock = new WebSocket(wsuri)
    //   this.websock.onmessage = this.websocketonmessage
    //   this.websock.onerror = this.websocketonerror
    //   this.websock.onclose = this.webscketonclose
    // },
    websocketonmessage(e) {
      // weosocket数据接收
      if (e.data === "success") {
        let flag = this.messageDatas;
        if (this.currentMsgId === "all") {
          flag.forEach((item) => {
            item.state = 1;
          });
          this.messageDatas = flag;
          this.currentMsgId = "";
          return;
        }
        flag.forEach((item) => {
          if (item.id === this.currentMsgId) {
            item.state = 1;
            this.currentMsgId = "";
          }
        });
        this.messageDatas = flag;
      } else if (e.data === "fail") {
        this.$message({
          message: "error",
          type: "error",
        });
      } else {
        this.messageDatas = JSON.parse(e.data);
        this.messageDatas = this.messageDatas.map((item) =>
          Object.assign({}, item, {
            content: item.content
              .replace(/margin-bottom:0.0000pt;/g, "margin:5px;padding:0;")
              .replace(/<a/g, "<span")
              .replace(/a>/g, "span>")
              .replace(/<br/g, "<div"),
          })
        );
        if (this.messageDatas[0] && this.messageDatas[0].state === 0) {
          this.$message({
            duration: 6000,
            // type: 'warning',
            message: this.messageDatas[0].srcContent,
          });
        }
      }
    },
    websocketsend(data) {
      // weosocket数据发送
      this.currentMsgId = data.id;
      data = JSON.stringify([data]);
      this.websock.send(data);
    },
    websocketsendall(data) {
      // 清空所有未读数据
      this.currentMsgId = "all";
      this.websock.send(data);
    },
    webscketonclose(e) {
      if (this.destroy === 0) {
        timeout = setTimeout(() => {
          clearTimeout(timeout);
          this.initWebSocket();
        }, 3000);
      }
    },
    websocketonerror(e) {
      // weosocket报错
      this.$message.error(e);
      timeout = setTimeout(() => {
        clearTimeout(timeout);
        this.initWebSocket();
      }, 3000);
    },

    setMouseStyle() {
      // 给顶部按钮添加鼠标移入移出样式
      const topBlockElements = document.getElementsByClassName("top-block");
      topBlockElements.forEach((item) => {
        item.addEventListener("mouseenter", this.onButtonMouseEnter);
        item.addEventListener("mouseleave", this.onButtonMouseLeave);
      });
      this.$once("hook:beforeDestroy", () => {
        topBlockElements.forEach((item) => {
          item.removeEventListener("mouseenter", this.onButtonMouseEnter);
          item.removeEventListener("mouseleave", this.onButtonMouseLeave);
        });
      });
    },
    onCheckedTheme(theme) {
      /**
       * 设置主题
       **/
      const head = document.querySelector("head");
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = `static/themes/${theme.themeName}/index.css`;
      head.appendChild(link);
      // 设置导航菜单和顶部样式
      this.$store.commit("setCurrentTheme", theme);
      // 设置全局主题颜色
      const documentElement = document.documentElement.style;
      documentElement.setProperty("--primary-color", theme.primaryColor);
      documentElement.setProperty("--success-color", theme.successColor);
      documentElement.setProperty("--warning-color", theme.warningColor);
      documentElement.setProperty("--danger-color", theme.dangerColor);
      documentElement.setProperty("--info-color", theme.infoColor);
    },
    toggleFullScreen,
    onButtonMouseEnter(e) {
      /**
       * 监听进入，改变背景色
       */
      e.target.style.backgroundColor = `${this.currentTheme.barTextColor}07`;
    },
    onButtonMouseLeave(e) {
      /**
       * 监听离开，恢复背景色
       */
      e.target.style.backgroundColor = `${this.currentTheme.barTextColor}00`;
    },
    toCurrentPage(menu) {
      /**
       * 菜单路由跳转
       */
      this.$router.push({ name: menu.name });
    },
    reload() {
      /**
       * 组件刷新，reload方法
       */
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    handleCompanyTeamChange(value) {
      /**
       * 切换企业或者团队
       */
      const globalCurrentCompany = this.$store.state.companyList.find(
        (item) => item.id === value[0]
      );
      let message = "";
      const { currentCompany, currentTeamId } = this.$store.state;
      if (currentCompany.id === globalCurrentCompany.id) {
        const team1 = currentCompany.teams.find((item) => item.id === currentTeamId);
        const team2 = globalCurrentCompany.teams.find((item) => item.id === value[1]);
        message = `You have switched from ${team1.name} to ${team2.name} `;
      } else {
        message = `You have switched from ${currentCompany.companyName} to ${globalCurrentCompany.companyName} `;
      }
      this.getMenusAndFunctionsByUser(this.user.id, value[0], "switch").then(() => {
        this.$store.commit("setCurrentCompany", globalCurrentCompany);
        this.$store.commit("setCurrentTeamId", value[1]);
        this.getUserListByCompany(globalCurrentCompany.id).then((val) => {
          const userExt = val.find((user) => user.userId === this.$store.state.user.id);
          this.$store.commit("setUserExt", userExt);
          this.$store.commit("setRoles", userExt.roles);
          this.$store.commit(
            "setPermissions",
            userExt.roles
              .filter((r) => r.code)
              .map((r) => {
                return r.code.toUpperCase();
              })
          );
        });
        this.$notify.success(message);
        this.$store.dispatch("getCompanySettings", globalCurrentCompany.id);
      });
    },
    getUserListByCompany(companyId) {
      return new Promise((resolve, reject) => {
        this.$request
          .get({
            url: `${this.$apis.userExtBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${companyId}`,
          })
          .then((res) => {
            if (res?.code === 1000) {
              const userList = (res?.data?.records || []).filter((item) => {
                const roles = item.roles || [];
                return !(roles.length === 1 && roles[0].code === this.$roleCode.ship);
              });
              resolve(userList);
            }
          });
      });
    },
    setCompanyOptions() {
      /**
       * 设置切换企业和团队的下拉选项
       */
      const { companyList } = this.$store.state;
      const companyOptions = [];
      companyList.forEach((company) => {
        if (company.enable) {
          const teamOptions = [];
          (company.teams ?? []).forEach((team) => {
            teamOptions.push({
              id: team.id,
              value: team.id,
              label: team.name,
              type: "team",
            });
          });
          companyOptions.push({
            id: company.id,
            value: company.id,
            label: company.companyName,
            children: teamOptions,
            type: "company",
          });
        }
      });
      this.companyOptions = companyOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "index";
</style>
